//@import '~antd/dist/antd.css';
@import '~antd/dist/reset.css';
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');
@import "./_variables.scss";

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ant-layout{
  background: $sbis-body-bg !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

// tables and filters
.container {
  border-radius: 0.375rem;
  background-color: $sbis-color-white;
  box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
}

.container-title{
  font-size: 20px;
  font-weight: 700;
}

.container-header {
  padding: 12px;
  background-color: $sbis-color-white;
  margin-bottom: 12px;
  display:flex;
  justify-content: space-between;
}

.container-table {
  padding: 12px;
  background-color: $sbis-color-white;
}

.container-form {
  padding: 12px;
  background-color: $sbis-color-white;
  display:flex;
  justify-content: space-between;
}
/** buttons **/
/*button{
  min-height: 30px !important;
  min-width: 120px !important;
  cursor: pointer;
}*/

.button-go{
  background-color: $sbis-button-go-bg;
  color: #FCFCFC;
  border: none;
  border-radius: 5px;
  &:hover, &:active{
    background-color: $sbis-button-go-bg-hover;
    color: $sbis-color-white;
    box-shadow: 1px 1px $sbis-button-go-bg;
  }
  &:focus{
    background-color: $sbis-button-go-bg-hover !important;
    color: $sbis-color-white !important;
  }
  &:disabled{
    opacity: 20;
    color: $sbis-color-black;
  }
}

.button-cancel{
  background-color: $sbis-button-cancel-bg;
  color: #FCFCFC;
  border: none;
  border-radius: 5px;
  &:hover, &:active{
    background-color: $sbis-button-cancel-bg-hover;
    color: #FCFCFC;
    box-shadow: 1px 1px $sbis-button-cancel-bg;
  }
  &:focus{
    background-color: $sbis-button-cancel-bg-hover !important;
    color: $sbis-color-white !important;
  }
  &:disabled{
    opacity: 20;
    color: $sbis-color-black;
  }
}