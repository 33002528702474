/* color variables */
$sbis-color-white: #FFF;
$sbis-color-black: #000;
$sbis-body-bg: #EDF2F9;
$sbis-content-color: #EDF2F9;

$sbis-button-go-bg: #FB9424;
$sbis-button-go-bg-hover: #F57C00;

$sbis-button-cancel-bg: #4C84C4;
$sbis-button-cancel-bg-hover: #1976d2;